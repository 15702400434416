<template>
  <b-container class="text-center">
    <base-logo-wave class="logo-404" />

    <h1>{{ $t('errors.404.title') }}</h1>
    <p>{{ error_message }}</p>

    <base-button
      class="btn-lg"
      :aTag=true
      :href="env.landing_page_url"
      target="_blank"
    >
      {{ $t('errors.404.go_to_wavetraders_btn') }}
    </base-button>
  </b-container >
</template>

<script>
import BaseLogoWave from '../../components/BaseLogoWave.vue'
import BaseButton from '../../components/BaseButton.vue'

export default {
  components: {
    BaseLogoWave,
    BaseButton
  },
  data () {
    return {
      env: {
        landing_page_url: process.env.VUE_APP_LANDING_PAGE_URL
      },
      error_message: this.$route.query.msg || this.$t('errors.404.description')
    }
  }
}
</script>

<style scoped lang="scss">
.logo-404 {
  margin-bottom: 70px;
}

h1 {
  font-size: 56px;
  color: var(--blueberry);
  margin-bottom: 14px;
  font-weight: 400;
}

p {
  font-family: 'Athelas', arial, sans-serif;
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: 0.1px;
  color: var(--charcoal-grey-white);
  margin-bottom: 32px;
}

.btn-lg {
  min-width: 224px;
}
</style>
